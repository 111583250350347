'use strict';
/* jslint browser: true */
/* global app */

var htmlClass = document.documentElement.className;

if(app.util.isTouch()) {
  app.util.addClass(document.documentElement, 'touch');
}

if(htmlClass.match(new RegExp(/^create-authentication$/))) {
  var f = new app.Filter({
    containerId : 'all-works',
    contentClass: 'item'
  });
}

else if(htmlClass.match(new RegExp(/^print-authentication$/))) {
  $('#letterhead-checkbox').on('change', function(e) {
    $('#admin-wrapper').toggleClass('show-letterhead');
  })
}

else if(htmlClass.match(new RegExp(/^edit-collection$/))) {
  var nextIndex = $('#collection .item').length;
  console.log(nextIndex);

  var f = new app.Filter({
    containerId : 'all-works',
    contentClass: 'item'
  });

  var removeFromCollection = function(e) {
    e.preventDefault();
    var it = $(this).parent('.item');
    var artistCode = it.data('artist-code');
    var numericCode = it.data('numeric-code');
    $('.input-group[data-artist-code="'+artistCode+'"][data-numeric-code="'+numericCode+'"]').remove();
    $('#collection .item[data-artist-code="'+artistCode+'"][data-numeric-code="'+numericCode+'"]').remove();
    $('#all-works .item[data-artist-code="'+artistCode+'"][data-numeric-code="'+numericCode+'"]').removeClass('inCollection');
  }

  var addToCollection = function(e) {
    e.preventDefault();
    var it = $(this).parent('.item');
    var artistCode = it.data('artist-code');
    var numericCode = it.data('numeric-code');
    var inputStr = '<div data-artist-code="'+artistCode+'" data-numeric-code="'+numericCode+'" class="input-group">' + 
                   '<input class="inArtistCode" type="text" name="paintings['+ nextIndex +'][paintingArtistCode]" value="'+artistCode+'">' +
                   '<input class="inNumericCode" type="text" name="paintings['+ nextIndex +'][paintingNumericCode]" value="'+numericCode+'"> '+
                   '<input class="inPrice" type="text" name="paintings['+ nextIndex +'][price]" value="">'+
                   '</div>';
    var copy = it.clone();
    it.addClass('inCollection');
    $(copy).append('<input type="text" placeholder="price" value="" class="collPrice">');
    $('#collection .grid').append(copy);
    $('form').append(inputStr);                   
    nextIndex++;

  }

  $('#collection').on('click', '.item a', removeFromCollection);
  $('#all-works').on('click', '.item.inCollection a', removeFromCollection);
  $('#all-works').on('click', '.item:not(.inCollection) a', addToCollection);
  $('#collection').on('click', '.item input.collPrice', function(e) {
    e.stopPropagation();
  });
  $('#collection').on('input', '.item input.collPrice', function(e) {
    var it = $(this).parent('.item');
    var artistCode = it.data('artist-code');
    var numericCode = it.data('numeric-code');
    console.log($('.input-group[data-artist-code="'+artistCode+'"][data-numeric-code="'+numericCode+'"] .inPrice'));
    $('.input-group[data-artist-code="'+artistCode+'"][data-numeric-code="'+numericCode+'"] .inPrice').val($(this).val());
  });
}